<template>
	<div class="container-fluid">
		<div class="row">
            <div class="col-md-12">
                <div class="card" style="margin-bottom: 78px">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-7 text-left"><h2 class="page-title"><i class="fas fa-users"></i> Leads</h2> </div>
                            <div class="col-5">
                                <!-- <input
                                    type="text"
                                    class="form-control search"
                                    v-model="search"
                                    placeholder="Search"
                                /> -->
                                <br />
                            </div>
                        </div>
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th style="width: 60px; text-align: center">ID</th>
                                    <th style="width: 120px; ">Date</th>
                                    <th style="width: 280px;">Location</th>
                                    <th style="">Customer</th>
                                    <th style="width: 100px">Quest</th>
                                    <th style="width: 100px; text-align: center">Status</th>
                                </tr>
                            </thead>
                            <tbody v-if="isLoading == false">
                                <tr v-for="(i, index) in filterItems" :key="i.id">
                                    <td style="width: 60px;text-align: center;">
                                        <span style="width: 100%; font-size:14px; ">
                                            #{{ i.id }}
                                        </span>
                                    </td>
                                    <td style="width: 120px;">{{ i.date_create }} <br />{{ i.hora }}</td>
                                    <td style="width: 280px; max-width: 280px;" class="location">
                                        <div v-if="i.ip_info!=null">
                                            <div v-if="typeof i.ip_info === 'object'">
                                                <span v-if="i.ip_info.city!=null">{{ i.ip_info.city }}&nbsp;</span>
                                                <span v-if="i.ip_info.zip!=null">{{ i.ip_info.zip }}.&nbsp;</span>
                                                <span v-if="i.ip_info.regionName!=null" :class="i.ip_info.regionName.length>10 ? 'showTootlip' : ''">
                                                    {{ i.ip_info.regionName.length>10 ? i.ip_info.regionName.slice(0, 10)+'...' : i.ip_info.regionName }}
                                                    <span v-if="i.ip_info.regionName.length>10" class="tooltip">{{ i.ip_info.regionName }}, {{ i.ip_info.country }}</span>
                                                </span>
                                                <span v-if="i.ip_info.countryCode!=null">{{ i.ip_info.countryCode }}.</span>
                                            </div>
                                            <div v-else>
                                                <!-- json es string -->
                                                There is no location.
                                            </div>
                                            <div class="referal showTootlip" v-if="i.webiste != null">
                                                Ref:
                                                <span class="badge badge-pill badge-light label-referal" style="font-size: 14px;">
                                                    {{ limpiarUrl(i.webiste).length>50 ? limpiarUrl(i.webiste).slice(0, 10)+'...' : limpiarUrl(i.webiste) }}
                                                </span>
                                                <span class="tooltip">{{ i.webiste }}</span>
                                            </div>
                                            <a :href="'https://ipinfo.io/'+i.ip+'#block-geolocation'" target="_blank">View Geo-Ip</a>
                                        </div>
                                        <div v-else-if="i.address!=null">
                                            {{ i.address }}<br>
                                            <a :href="'https://ipinfo.io/'+i.ip+'#block-geolocation'" target="_blank">View Geo-Ip</a>
                                        </div>
                                        <div v-else>
                                            <a :href="'https://ipinfo.io/'+i.ip+'#block-geolocation'" target="_blank">View Geo-Ip</a>
                                        </div>
                                    </td>
                                    <td class="customer">
                                        <div v-if="validQuestData(i.quest)!=null">
                                            <div v-if="i.quest.firsname =='' && i.quest.lastname =='' && i.quest.phone=='' && i.quest.email=='' && i.quest.address==''">
                                                <div class="">
                                                    Uncompleted information.<br>
                                                    <span class="badge badge-pill badge-light">They have not provided all the information.</span>
                                                </div>
                                            </div>
                                            <div v-else>
                                                <div v-if="i.quest.firsname !='' || i.quest.lastname !=''">
                                                    {{ i.quest.firsname }} {{ i.quest.lastname }}
                                                </div>
                                                <div v-if="i.quest.phone!='' || i.quest.email!=''">
                                                {{ i.quest.phone }} - {{ i.quest.email }}
                                                </div>
                                                <div v-if="i.quest.address!=''">
                                                {{ i.quest.address }}
                                                </div>
                                            </div>
                                        </div>
                                        <div v-else class="">
                                            No data available.<br>
                                            <span class="badge badge-pill badge-light">It appears that the data is unavailable or empty.</span>
                                        </div>
                                    </td>
                                    <td class="quests">
                                        <button
                                            type="button"
                                            class="btn btn-primary btn-sm"
                                            @click="EstPreview(i)"
                                            v-if="validQuestData(i.quest)!=null"
                                        >
                                            <i class="fas fa-eye"></i> View Data
                                        </button>
                                        <button
                                            type="button"
                                            class="btn btn-light btn-sm"
                                            v-else
                                        >
                                            <i class="fas fa-ban"></i> Missing data
                                        </button>
                                    </td>
                                    <td class="status">
                                        <div
                                            class="action_order_btn"
                                            style="width: 100px"
                                        >
                                            <span
                                                @click="activeStatus(index)"
                                                style="cursor: pointer"
                                            >
                                                <span
                                                    v-if="i.status == 0"
                                                    class="badge badge-pill badge-warning"
                                                    >Pending
                                                    <i class="fas fa-angle-down"></i
                                                ></span>
                                                <span
                                                    v-else-if="i.status == 1"
                                                    class="badge badge-pill badge-info"
                                                    >Verified Phone
                                                    <i class="fas fa-angle-down"></i
                                                ></span>
                                                <span
                                                    v-else-if="i.status == 2"
                                                    class="badge badge-pill badge-primary"
                                                    >Estimate Send
                                                    <i class="fas fa-angle-down"></i
                                                ></span>
                                                <span
                                                    v-else-if="i.status == 3"
                                                    class="badge badge-pill badge-success"
                                                    >Acepted
                                                    <i class="fas fa-angle-down"></i
                                                ></span>
                                                <span
                                                    v-else-if="i.status == 4"
                                                    class="badge badge-pill badge-danger"
                                                    >Rejected
                                                    <i class="fas fa-angle-down"></i
                                                ></span>
                                                <span
                                                    v-else-if="i.status == 5"
                                                    class="badge badge-pill badge-danger"
                                                    >Spam
                                                    <i class="fas fa-angle-down"></i
                                                ></span>
                                            </span>
                                            <span
                                                class="status_list"
                                                v-if="statusActive == index"
                                            >
                                                <ul class="reset" style="padding-left: 0">
                                                    <li @click="statusChange(0, i.id)">
                                                        <span
                                                            class="badge badge-pill badge-warning"
                                                            >Pending</span
                                                        >
                                                    </li>
                                                    <li @click="statusChange(1, i.id)">
                                                        <span
                                                            class="badge badge-pill badge-info"
                                                            >Verified Phone</span
                                                        >
                                                    </li>
                                                    <li @click="statusChange(2, i.id)">
                                                        <span
                                                            class="badge badge-pill badge-primary"
                                                            >Estimate Send</span
                                                        >
                                                    </li>
                                                    <li @click="statusChange(3, i.id)">
                                                        <span
                                                            class="badge badge-pill badge-success"
                                                            >Acepted</span
                                                        >
                                                    </li>
                                                    <li @click="statusChange(4, i.id)">
                                                        <span
                                                            class="badge badge-pill badge-danger"
                                                            >Rejected</span
                                                        >
                                                    </li>
                                                    <li @click="statusChange(5, i.id)">
                                                        <span
                                                            class="badge badge-pill badge-danger"
                                                            >Spam</span
                                                        >
                                                    </li>
                                                </ul>
                                            </span>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody v-if="isLoading">
                                <tr>
                                    <th colspan="7"><Loader></Loader></th>
                                </tr>
                            </tbody>
                        </table>
                        <!--<ul
                            v-if="
                                pagination.total > pagination.per_page && content == false
                            "
                            class="pagination justify-content-center"
                            style="borde: 1px solid blue"
                        >
                            <li v-if="pagination.current_page !== 1" class="page-car">
                                <button
                                    class="page-link"
                                    aria-label="Previous"
                                    v-on:click.prevent="
                                        changePage(pagination.current_page - 1)
                                    "
                                >
                                    <span aria-hidden="true">«</span>
                                </button>
                            </li>
                            <li
                                v-for="(page, index) in pagesNumber"
                                :key="index"
                                class="page-car"
                                :class="{ active: page == pagination.current_page }"
                            >
                                <button
                                    class="page-link"
                                    v-on:click.prevent="changePage(page)"
                                >
                                    {{ page }}
                                </button>
                            </li>
                            <li
                                v-if="pagination.current_page !== pagination.last_page"
                                class="page-car"
                            >
                                <button
                                    class="page-link"
                                    aria-label="Next"
                                    v-on:click.prevent="
                                        changePage(pagination.current_page + 1)
                                    "
                                >
                                    <span aria-hidden="true">»</span>
                                </button>
                            </li>
                        </ul> -->
                    </div>
                </div>
            </div>
        </div>
        <modal
            name="modal-estimate"
            height="auto"
            :resizable="true"
            :adaptive="true"
            :scrollable="true"
        >
            <div class="card-header" style="position: relative">
                <span style="font-size: 18px">Estimate</span>
                <i
                    class="fas fa-times"
                    style="
                    position: absolute;
                    top: 9px;
                    right: 11px;
                    font-size: 24px;
                    cursor: pointer;
                    "
                    @click="CloseModal"
                ></i>
            </div>
            <div class="card-body">
                <div style="display: flex">
                    <b style="width: 300px">{{q.zipcode}}:</b>
                    <span style="width: 200px"> {{ quest.zipcode }}</span>
                </div>
                <div style="display: flex">
                    <b style="width: 300px">{{q.customer}}:</b>
                    <span style="width: 200px"> {{ quest.firsname }} {{ quest.lastname }}</span>
                </div>
                <div style="display: flex">
                    <b style="width: 300px">{{q.phone}}:</b>
                    <span style="width: 200px"> {{ quest.phone }}</span>
                </div>
                <div style="display: flex">
                    <b style="width: 300px">{{q.email}}:</b>
                    <span style="width: 200px"> {{ quest.email }}</span>
                </div>
                <div style="display: flex">
                    <b style="width: 300px">{{q.address}}:</b>
                    <span style="width: 200px"> {{ quest.address }}</span>
                </div>
                <div style="display: flex">
                    <b style="width: 300px">Location Ip:</b>
                    <span style="width: 200px" v-if="ip_info!=null">{{ ip_info.countryCode}}. {{ ip_info.regionName}}, {{ ip_info.city}} {{ ip_info.zip }}</span>
                </div>
            <div style="display: flex">
                <b style="width: 300px"></b>
                <span style="width: 200px"> {{ ip }} / <a :href="'https://ipinfo.io/'+ip+'#block-geolocation'" target="_blank">View Geo-Ip</a></span>
            </div>
            <div style="display: flex">
                <h4><b>Project related questions:</b></h4>
            </div>
            <div style="display: flex;flex-direction: column;" v-if="questAnswers.length==0">
                <div style="display: flex">
                <b style="width: 300px">{{q.project}}:</b>
                <span style="width: 200px"> {{ quest.project }}</span>
                </div>
                <div style="display: flex">
                <b style="width: 300px">{{q.material}}:</b>
                <span style="width: 200px"> {{ quest.material }}</span>
                </div>
            </div>
            <div style="display: flex;flex-direction: column;" v-else>
                <div style="display: flex" v-for="(i, index) in questAnswers" :key="index">
                <b style="width: 300px">{{i.questName}}:</b>
                <span style="width: 200px">
                    <span v-for="(a,idx) in i.answers" :key="idx">
                    {{a}}<span v-if="idx<(i.answers.length-1)">,</span>
                    </span>
                </span>
                </div>
            </div>
            <div style="display: flex">
                <b style="width: 300px">{{q.otherdetails}}:</b>
                <span style="width: 200px"> {{ quest.otherdetails }}</span>
            </div>
            <div style="display: flex">
                <b style="width: 300px">Url referred:</b>
                <span style="width: 200px">
                    <div class="referal" v-if="urlOrigin != null">
                        <span class="badge badge-pill badge-light label-referal" style="font-size: 14px;">
                            {{ limpiarUrl(urlOrigin).length>50 ? limpiarUrl(urlOrigin).slice(0, 10)+'...' : limpiarUrl(urlOrigin) }}
                        </span>
                    </div>
                </span>
            </div>
            </div>
        </modal>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import Loader from "@/core/components/shared/Loader.vue";
export default {
	name: 'RoofCalLeads',
	components: {
    Loader,
	},
    data() {
        return {
            isLoading: false,
            statusActive: null,
            leadsList: [],
            quest: [],
            questAnswers: [],
            search: "",
            offset: 4,
            page: 1,
            pagination: {
                current_page: 1,
            },
            query: "",
            result: "",
            content: false,
            ip: '',
            ip_info: '',
            ipurl: '',
            urlOrigin: '',
            datecreate: '',
            type: 1,
            apis: {},
            q: []
        };
    },
    created() {
        this.init()
    },
    methods: {
        init() {
        let urlApis = this.$store.getters['ModApps/RoofCal/urlApis'];
        this.apis = urlApis.leads;
        this.getLeads(1);
        },
        getLeads(sinLoad) {
            if(sinLoad==1){this.isLoading = true;}
            this.statusActive = null;
            window.master.post(this.apis.get(), {
                org_id: window.localorgdata,
                user_id: window.localAccountID,
            })
            .then((response) => {
                console.log('data leads', response.data);
                this.leadsList = response.data.r;
                this.q = response.data.q;
                this.isLoading = false;
            })
            .catch((error) => console.log(error));
        },
        activeStatus(index) {
            if (this.statusActive == index) {
                this.statusActive = null;
            } else {
                this.statusActive = index;
            }
        },
        statusChange(status, id) {
            console.log('statusChange', status, id);
            this.statusActive = null;
            window.master.post(this.apis.status(), {
                id: id,
                status: status,
            })
            .then((response) => {
                this.getLeads();
            })
            .catch((error) => {console.log(error);});
        },
        EstPreview(i) {
            this.quest = i.quest;
            if(i.quest.questAnswers != undefined){
            this.questAnswers = JSON.parse(i.quest.questAnswers);
            }else{
            this.questAnswers =[];
            }
            this.ip = i.ip;
            this.ip_info = i.ip_info;
            this.ipurl = i.ipurl;
                    this.urlOrigin = i.webiste;
                    this.datecreate = i.date_create +' - '+i.hora;
            this.$modal.show("modal-estimate");
        },
        CloseModal() {
            this.$modal.hide("modal-estimate");
        },
        limpiarUrl(url) {
            try {
                // Crea un objeto URL para parsear fácilmente
                let limpia = new URL(url);
                // Extrae el hostname y remueve "www." si lo tiene
                let dominio = limpia.hostname.replace(/^www\./, '');
                return dominio;
            } catch (e) {
                // Si no es una URL válida, podrías manejarlo o devolver null
                // console.error('URL inválida:', url);
                return url;
            }
        },
        limpiarYConvertirJson(data) {
            let intento = data;
            let contador = 0;
            try {
                while (typeof intento === 'string' && contador < 5) {
                    intento = intento.trim();
                    // Si falta envolverlo en llaves (caso 2)
                    if (!intento.startsWith('{') && intento.includes('":"')) {
                    intento = `{${intento}}`;
                    }
                    intento = JSON.parse(intento);
                    contador++;
                }
                return (typeof intento === 'object') ? intento : null;
            } catch (error) {
                // console.log('Error al convertir a JSON:', error, data);
                return null;
            }
        },
        validQuestData(data) {
            try {
                let dataJson = this.limpiarYConvertirJson(data);
                if (dataJson != null && typeof dataJson === 'object') {
                    // console.log('validQuestData', dataJson);
                    let iterar = dataJson;
                    let valid = false;
                    for (const key in iterar) {
                        if (Object.prototype.hasOwnProperty.call(iterar, key)) {
                            const element = iterar[key];
                            if (element != null && element.length > 0) {
                                // console.log('validQuestData si tiene datos validos', element)
                                valid=true;
                                break
                            }
                        }
                    }
                    if(valid) return dataJson;
                    return null;
                }
                    return null;
            } catch (error) {
                // console.log('Error al validar datos de Quest:', error, data);
                return false;
            }
        },
    },
    watch: {
        accitonPusher: function () {
            let t = this;
            if (t.accitonPusher != undefined) {
                let data = t.accitonPusher;
                if(window.localorgdata == data.org_id && window.master_client == data.user_id){
                    if(data.action === "RoofCalLeads"){
                        this.getLeads(0);
                    }
                    else if(data.action === "RoofCalLeads validPhone"){
                        this.getLeads(0);
                    }
                }
            }
        },
    },
    computed: {
        ...mapGetters("core", {
            accitonPusher: "get_accitonPusher"
        }),
        filterItems: function () {
            let self = this;
            if (this.search !== "") {
                self.content = true;
                return this.leadsList.filter(function (item) {
                    if(item.quest == null){
                        return (
                            item.id.toString().includes(self.search) ||
                            item.date_create.toString().includes(self.search) ||
                            item.ip.toString().includes(self.search) ||
                            item.webiste.toString().includes(self.search)
                        );
                    }
                    return (
                        item.id.toString().includes(self.search) ||
                        item.date_create.toString().includes(self.search) ||
                        item.ip.toString().includes(self.search) ||
                        item.webiste.toString().includes(self.search) ||
                        item.quest.firsname.toString().includes(self.search) ||
                        item.quest.lastname.toString().includes(self.search) ||
                        item.quest.email.toString().includes(self.search)
                    );
                });
            } else {
                self.content = false;
                return this.leadsList.filter(function (item) {
                    if(typeof item.ip_info != 'object' || item.ip_info == null){
                        // console.log('Limpieza de  JSON: ', item.id, item.ip_info);
                        item.ip_info = self.limpiarYConvertirJson(item.ip_info);
                    }
                    else{
                        // console.log('JSON valido: ', item.id, item.ip_info);
                    }
                    return item;
                });
            }
        },
    },
};
</script>
<style scoped lang="scss">
.action_order_btn {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    justify-content: center;
}
.status_list {
    padding: 8px 7px;
    position: absolute;
    left: 0;
    top: 25px;
    background-color: #fff;
    width: 120px;
    font-size: 14px;
    z-index: 9;
    ul {
        li {
            display: block;
            position: relative;
            margin: 1px 0;
            padding: 5px;
            span {
                cursor: pointer;
            }
            &:hover {
            }
        }
    }
}
.pagination {
    .page-car {
        &.active {
            .page-link {
                background-color: #8b8b8c;
                color: #fff;
            }
        }
    }
}
.search {
    border-color: #555;
}
.showTootlip {
    .tooltip {
        opacity: 0;
        visibility: hidden;
    }
    &:hover, &:focus {
        .tooltip {
            opacity: 1;
            visibility: visible;
            font-size: 14px;
        }
    }
}
</style>
